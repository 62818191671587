
import { defineComponent, ref, watch, onBeforeMount } from 'vue'
import Swal from 'sweetalert2'
import MultiSelect from '@vueform/multiselect'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: {
    MultiSelect,
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  emits: [
    'update:form'
  ],
  setup (props, { emit }) {
    const keywords = ref<string[]>()

    const { t } = useI18n()

    const options = ref<{ value: string; label: string }[]>([])

    watch(keywords, (keywords) => {
      if (keywords) {
        emit('update:form', {
          keywords: Object.values(keywords)
        })
      }
    })

    const openInfo = () => {
      Swal.fire({
        title: t('page.settings.category.action.keyword.help_message'),
        confirmButtonText: t('state.settings.category.about_keywords.action_confirm'),
        html: t('state.settings.category.about_keywords.message-html')
      })
    }

    onBeforeMount(() => {
      if ('keywords' in props.form) {
        keywords.value = Object.values(props.form.keywords)

        options.value = keywords.value.map((k) => {
          return {
            value: k,
            label: k
          }
        })
      }
    })

    return {
      options,
      keywords,
      openInfo
    }
  }
})
